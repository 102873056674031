import { Link } from "gatsby"
import React, { useState } from "react"
import "../components/i18n"
import { useTranslation } from "react-i18next"
import LanguageSwitcher from "./LanguageSwitcher"
import Headroom from "react-headroom"

const Header = ({}) => {
  const [opened, setOpened] = useState(false)
  const { t, ready } = useTranslation("nav")

  return !ready ? null : (
    <Headroom>
      <header>
        <nav role="navigation">
          <div id="menuToggle">
            <input
              aria-label="hamburger menu"
              type="checkbox"
              checked={opened}
              onChange={() => {
                console.log("change")
                setOpened(!opened)
              }}
            />
            <span className="hamburger"></span>
            <span className="hamburger"></span>
            <span className="hamburger"></span>
            <div id="hamburger_menu">
              <ul id="menu">
                <li>
                  <Link
                    to="/#about"
                    onClick={() => {
                      setOpened(false)
                    }}
                  >
                    {t("about")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/#contact"
                    onClick={() => {
                      setOpened(false)
                    }}
                  >
                    {t("contact")}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
                </li>
              </ul>
              <LanguageSwitcher />
            </div>
          </div>
        </nav>

        <div className="header_left">
          <Link to="/#download" className="button">
            {t("downloadApp")}
          </Link>
        </div>

        <div className="header_links">
          <Link to="/#about">{t("about")}</Link>
          <Link to="/#contact">{t("contact")}</Link>
          <Link to="/privacy-policy">{t("privacyPolicy")}</Link>
          <LanguageSwitcher />
        </div>
      </header>
    </Headroom>
  )
}

export default Header

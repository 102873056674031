import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "semantic-ui-react"
import "semantic-ui-css/components/dropdown.min.css"
import "semantic-ui-css/components/icon.min.css"
import "semantic-ui-css/components/button.min.css"
import "semantic-ui-css/components/label.min.css"
import "semantic-ui-css/components/transition.min.css"

const languages = [
  { key: "sr", text: "Srpski", value: "sr" },
  { key: "en", text: "English", value: "en" },
]

const LanguageSwitcher = () => {
  const [selected, setSelected] = useState("Srpski")
  const { i18n } = useTranslation("index")
  const handleChangeLanguage = lng => {
    i18n.changeLanguage(lng)
  }

  return (
    <Dropdown
      aria-label="language switcher"
      button
      className="icon"
      floating
      labeled
      icon="world"
      options={languages}
      // search
      text={selected}
      onChange={(e, data) => {
        console.log(data, "dataaa")
        handleChangeLanguage(data?.value)
        setSelected(languages.find(lang => lang.value === data.value).text)
      }}
    />
  )
}

export default LanguageSwitcher
